import Topbar from "./components/topbar/Topbar";
import Top from "./components/top/Top";
import Header from "./components/header/Header";
import Start from "./components/start/Start";
import Intro from "./components/intro/Intro";
import About from "./components/about/About";
import Skills from "./components/skills/Skills";
import ProductList from "./components/productList/ProductList";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import "./app.scss";
import React, { useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Menu from "./components/menu/Menu";

function App() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="app backpattern">
      <Top menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Header />
      <Start />
      <Skills />
      <ProductList />
      <About />
      <Intro />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
