import "./Header.css";

function Header() {
  return (
    <>
      <div className="header-container tc" id="header">
        <div className="s-container">
          <h2
            className="developer-title"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="300"
            data-aos-anchor-placement="top-bottom"
          >
            Javascript Developer
          </h2>
          <div className="developer-skills-wrapper">
            <div className="intro-skills">
              <div className="intro-skills-wrapper">
                <div className="intro-skills-item">JavaScript</div>
                <div className="intro-skills-item">React</div>
                <div className="intro-skills-item">NextJS</div>
                <div className="intro-skills-item">NodeJS</div>
                <div className="intro-skills-item">Web developer</div>
                <div className="intro-skills-item">Software developer</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
