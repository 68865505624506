import React from "react";

const Card = ({ name, badge, id }) => {
  return (
    <div
      className="tc dib br3 pa2 ma1 grow bw2 shadow-5"
      style={{ width: "160px", cursor: "default" }}
    >
      <img src={badge} alt={name} style={{ width: "60px" }} />
      <div>
        <h2 className="s-desc white">{name}</h2>
      </div>
    </div>
  );
};

export default Card;
