import IntroImg from "../../img/leaf_left.png";
import LeafRightImg from "../../img/leaf_right.png";
import projectOne from "../../img/multi-micro-store-24-git.png";
import projectTwo from "../../img/ai-2024-git.png";
import projectThree from "../../img/sportdata-24-git.png";

import "./Start.scss";

export default function Start() {
  return (
    <>
      <div className="image-wrapper">
        <img src={IntroImg} alt="" className="leaf-img" />
        <img src={LeafRightImg} alt="" className="leaf-img" />
      </div>
      <div className="start" id="intro">
        <div className="start-left">
          <div className="row">
            <div className="column">
              <img src={projectOne} alt="projectOne" />
            </div>
            <div className="column">
              <img src={projectTwo} alt="projectTwo" />
            </div>
            <div className="column">
              <img src={projectThree} alt="projectThree" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
