import Email from "../../img/email.png";
import Address from "../../img/address.png";
import { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "./contact.scss";

export default function Contact() {
  const formRef = useRef();
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_oz92q8v",
        "template_khzskgm",
        formRef.current,
        "user_83iut0ltHexzhXQ2piWeI"
      )
      .then(
        (result) => {
          // console.log(result.text);
          setDone(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="c" id="contact">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div
          className="c-left"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-easing="ease-out-cubic"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="c-info">
            <div className="c-info-item">
              <img className="c-icon" src={Email} alt="" />
              Here you can send me email
            </div>
            <div className="c-info-item">
              <img className="c-icon" src={Address} alt="" />
              Budapest, Hungary
            </div>
          </div>
        </div>
        <div
          className="c-right"
          data-aos="fade-up"
          data-aos-easing="ease-out-cubic"
          data-aos-delay="800"
          data-aos-once="true"
          data-aos-anchor-placement="top-bottom"
        >
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              required
              minLength="3"
              type="text"
              placeholder="Name"
              name="user_name"
            />
            <input type="text" placeholder="Subject" name="user_subject" />
            <input
              required
              minLength="5"
              type="text"
              placeholder="Email"
              name="user_email"
            />
            <textarea
              required
              minLength="5"
              rows="10"
              placeholder="Message"
              name="message"
            />
            <button>Send email to me</button>
            {done && (
              <div className="toast-item success">
                <div className="toast success">
                  <h4>Your message has been sent successfully.</h4>
                  <p>I will reply as soon as possible. Andras</p>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
