export const products = [
  {
    id: 1,
    img: "images/projects/multi-micro-store-24.webp",
    link: "https://multi-micro-store-24.onrender.com/",
    code: "https://github.com/vargaae/multi-micro-store-24",
    title: "MULTI E-commerce Application with MICROSERVICES",
    stack: "{React} {Redux} {SASS} {TailwindCSS} {PSQL} {NodeJS} {StrAPI} {Firebase} {Stripe} ",
    info1: `This project is an online B2C shopping application that contains a basic e-commerce’s
    functions from shopping to placing order with
    using microservices (!Demonstration only - Microservices are being further developed!)`,
    info2: `Cloud Application Platform: STORE SERVICE: API and Database for Design store products with headless CMS: Strapi and PostgreSQL database - microservice entirely in the cloud.`,
    info3: `Design SHOP SERVICE: Database for products with Firebase,`,
    info4: `Authentication with email 
    registration and with Google account, payment with Stripe.`,
  },
  {
    id: 2,
    img: "images/projects/ai-2024.png",
    link: "https://ai-2024.onrender.com/",
    code: "https://github.com/vargaae/ai-2024",
    title: "AI Analyser and Generator",
    stack: "{React} {Redux} {SASS} {TailwindCSS} {PostgreSQL} {NodeJS}",
    info1: `AI inspection and creator tool application with OpenAI-ChatGPT@Dall-E, Clarifai API. 
    Clarifai is an API that provides us data predicts data on our images.`,
    info2: `Server side: REST API with Node JS-express server and
    PostgreSQL database. Authentication and Authorization - set with email 
    registration-log in system on the server side`,
    info3: `ReactJS Application on the client side that handles the Clarifai API from REST API.`,
    info4: `Cloud Application Platform: deployed on Render 
    both Back End, Front End applications and the SQL Database entirely in the cloud.`,
  },
  {
    id: 3,
    img: "images/projects/sportdata-1.png",
    link: "https://sportdata-next-2024.onrender.com/",
    code: "https://github.com/vargaae/sportdata-next-2024",
    title: "SportDATA App",
    stack: " {NextJS} {React 18.2} {TS} {TailwindCSS}",
    info1: "SportDATA App for anybody who follows, watches sport championships and matches.",
    info2: `Sport News and DATA from APIs with a fullstack application with the power of Next JS`,
    info3: `Football Leagues-Matches filtering by leagues and time`,
    info4: `Fetches data from football-data.org and other APIs.`,
  },
  // {
  //   id: 2,
  //   img: "https://github.com/vargaae/football-bg/raw/main/public/footballapp.png",
  //   link: "https://sport-2024.onrender.com/",
  //   code: "https://github.com/vargaae/sport-2024",
  //   title: "SportDATA App",
  //   stack: "{React 18.2} {JS} {Redux} {SASS} {MaterialUI}",
  //   info1: "SportDATA App for anybody who follows, watches sport championships and matches.",
  //   info2: `SportDATA App fetches data from football-data.org and other APIs with RTK API Query.`,
  //   info3: `Administrator functions –  ...`,
  //   info4: `Loading speed optimalization: Modularization with Lazy Loading. The whole application is connected and working with 
  //   Cloud database.`,
  // },
    // {
    //   id: 3,
    //   img: "https://github.com/vargaae/football-bg/raw/main/public/footballapp.png",
    //   link: "https://kanban-fire-workshop-f3fa2.web.app/",
    //   code: "https://github.com/vargaae/football-bg",
    //   title: "Reality Series App",
    //   stack: "{React 18.2} {JS} {Redux} {SASS} {MaterialUI}",
    //   info1: "SportDATA App for anybody who follows, watches sport championships and matches.",
    //   info2: `SportDATA App fetches data from football-data.org with RTK API Query.`,
    //   info3: `Administrator functions –  ...`,
    //   info4: `Loading speed optimalization: Modularization with Lazy Loading. The whole application is connected and working with 
    //   Cloud database.`,
    // },
    // {
    //   id: 4,
    //   img: "images/projects/kanban-manager.jpg",
    //   link: "https://kanban-fire-workshop-f3fa2.web.app/",
    //   code: "https://github.com/vargaae/kanban-manager",
    //   title: "Kanban Manager",
    //   stack: "{Angular} {TS} {SASS} {Material} {Bootstrap} {Firebase}",
    //   info1: "Project Management tool for software developers.",
    //   info2: `The application has two main parts that work together, 
    //   a Kanban Board with drag and drop planning board , and 
    //   NotePad, that is working as an issue tracker tool with 
    //   several synchronised functions.`,
    //   info3: `Administrator functions –  edit the projects'
    //   and the developers' files with CRUD operations.`,
    //   info4: `Loading speed optimalization: Modularization with Lazy Loading. The whole application is connected and working with 
    //   Cloud database.`,
    // },
        // {
    //   id: 3,
    //   img: "images/projects/bugtracker.png",
    //   link: "https://vargaae.github.io/bug-tracker-vue/index.html",
    //   code: "https://github.com/vargaae/bug-tracker-vue",
      // title: "Bug Tracker Project",
    //   stack: "{VueJS} {VueX} {php}",
    //   info1: "BugTracker application helps to handle Bugs while the software development.",
    //   info2: "php REST API: Routes - CRUD Operations",
    //   info3: `Server side: REST API with Node JS-express server and
      // PostgreSQL database. Authentication and Authorization - set with email 
      // registration-log in system on the server side`,
    //   info4: "VueJS on the Client Side with Vuex",
    // },
    // {
    //   id: 5,
    //   img: "images/projects/ashop.jpg",
    //   link: "https://ashop-23e4c.web.app/",
    //   code: "https://github.com/vargaae/ashop",
    //   title: "E-commerce Application",
    //   stack: "{Angular} {TS} {SASS} {Material} {Bootstrap} {Firebase}",
    //   info1: `This project is an online B2C shopping application that contains a basic e-commerce’s
    //   functions from shopping to placing order with
    //   using real-time database`,
    //   info2: `Administrator functions – CRUD operations - Roles - Manage products - Data table - RxJS`,
    //   info3: `Loading speed optimalization: Modularization with Lazy Loading`,
    //   info4: `Authentication and Authorization - set with email 
    //   registration-log in system (Sign Up-Email confirmation-Forgot Password Change) and Google account log in.`,
    // },
  ];