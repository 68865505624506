import React from "react";
import Card from "./Card";

const CardList = ({ skillset }) => {
  return skillset.map((user, i) => {
    return (
      <Card
        key={i}
        id={skillset[i].id}
        name={skillset[i].name}
        badge={skillset[i].badge}
      />
    );
  });
};

export default CardList;
