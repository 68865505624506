import "./product.scss";
// import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
// import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';

const Product = ({
  img,
  link,
  code,
  title,
  stack,
  info1,
  info2,
  info3,
  info4,
}) => {
  return (
    <>
      <div className="thumb-container">
        <a href={link} target="_blank" rel="noreferrer">
          <div className="thumbnail" data-aos="fade-up" data-aos-once="true">
            {/* <div
            className="thumbnail"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          > */}
            <div className="thumbnail__container">
              <div className="thumbnail__img">
                <div className="thumbnail__content">
                  <h2 className="thumbnail__caption">Live Demo Application</h2>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a href={code} target="_blank" rel="noreferrer">
          <div className="thumbnail" data-aos="fade-up" data-aos-once="true">
            {/* <div
            className="thumbnail"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-cubic"
            data-aos-anchor-placement="top-bottom"
          > */}
            <div className="thumbnail__container">
              <div className="thumbnail__img_code">
                <div className="thumbnail__content">
                  <h2 className="thumbnail__caption">View Code on GitHUB</h2>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className="projects">
        <div className="p-browser">
          <div className="p-circle"></div>
          <div className="p-circle"></div>
          <div className="p-circle"></div>
          <div className="p-title">{title}</div>
        </div>
        <a href={link} target="_blank" rel="noreferrer">
          <img src={img} alt="" className="p-img" />
        </a>
      </div>
      <div className="p-devtools" data-aos="fade-right" data-aos-once="true">
        {/* <div
        className="p-devtools"
        data-aos="fade-right"
        data-aos-once="true"
        data-aos-easing="ease-out-cubic"
        data-aos-anchor-placement="top-bottom"
      > */}
        <div className="p-dev-tools">
          <div className="p-dev-browser">
            {/* <HighlightAltIcon className="icon" />
          <AddToHomeScreenIcon className="icon" /> */}
            <p>Elements</p>
            <p>Console</p>
            <p>Sources</p>
            <p>Network</p>
            <p>{">>"}</p>
          </div>
          <div className="app-details">
            <div className="console">Console was cleared</div>
            <h2 className="app-title">{title}</h2>
            <h3>About the application</h3>
            <p className="stack p-info">
              {" "}
              <span>{">"} </span> {stack}{" "}
            </p>
            <p className="p-info">
              {" "}
              <span>{">"} </span> {info1}{" "}
            </p>
            <p className="p-info">
              {" "}
              <span>{">"} </span> {info2}{" "}
            </p>
            <p className="p-info">
              {" "}
              <span>{">"} </span> {info3}{" "}
            </p>
            <p className="p-info">
              {" "}
              <span>{">"} </span> {info4}{" "}
            </p>
          </div>
        </div>
        <div className="thumb-container-sm">
          <a href={link} target="_blank" rel="noreferrer">
            <div className="thumbnail_demo_sm">Live Demo Application</div>
          </a>
          <a href={code} target="_blank" rel="noreferrer">
            <div className="thumbnail_code_sm">View Code on GitHUB</div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Product;
