import "./top.scss";

export default function Top({ menuOpen, setMenuOpen }) {
  return (
    <div id="intro" className={"top-container " + (menuOpen === false && "active")}>
      <div className="wrapper">
        <div className="left">
          <div className="displayName">
            <a href="#intro">ANDRAS VARGA</a>
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
}
