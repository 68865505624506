import "./skills.css";
import { skillset } from "../skillset";
import CardList from "./CardList";

function Skills() {
  return (
    <>
      <div className="skills tc" id="skills">
        <div className="s-container">
          <h2
            className="s-title"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="300"
            data-aos-anchor-placement="top-bottom"
          >
            {"<"}Skills{">"}
          </h2>
          <p className="s-desc white">[Main Web Development Skills & Tools]</p>
          <div className="s-wrapper">
            <div
              className="skillset"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-easing="ease-out-cubic"
              data-aos-delay="300"
              data-aos-anchor-placement="top-bottom"
            >
              <CardList skillset={skillset} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Skills;
