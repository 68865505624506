export const skillset = [
  {
    id: 4,
    name: "JavaScript",
    badge: "images/img-svg-skills/javascript.svg",
  },
  {
    id: 9,
    name: "nodeJS",
    badge: "images/img-svg-skills/Node.js_logo.svg",
  },
  {
    id: 3,
    name: "Typescript",
    badge: "images/img-svg-skills/Typescript_logo_2020.svg",
  },
  {
    id: 21,
    name: "NextJS",
    badge: "images/img-svg-skills/next.svg",
  },
  {
    id: 1,
    name: "React",
    badge: "images/img-svg-skills/react.svg",
  },
  {
    id: 22,
    name: "Angular",
    badge: "images/img-svg-skills/angular.svg",
  },
  {
    id: 5,
    name: "HTML+CSS",
    badge: "images/img-svg-skills/html-css.svg",
  },
  {
    id: 6,
    name: "Sass",
    badge: "images/img-svg-skills/sass.svg",
  },
  {
    id: 7,
    name: "Redux",
    badge: "images/img-svg-skills/redux.svg",
  },
  {
    id: 8,
    name: "NgRx",
    badge: "images/img-svg-skills/ngrx.svg",
  },
  {
    id: 10,
    name: "Mongodb",
    badge: "images/img-svg-skills/mongodb.svg",
  },
  {
    id: 11,
    name: "SQL",
    badge: "images/img-svg-skills/mysql.svg",
  },
  {
    id: 12,
    name: "TailwindCSS",
    badge: "images/img-svg-skills/tailwindcss.svg",
  },
  {
    id: 13,
    name: "Material",
    badge: "images/img-svg-skills/material.svg",
  },
  {
    id: 16,
    name: "Illustrator",
    badge: "images/img-svg-skills/Adobe_Illustrator_Icon_(CS6).svg",
  },
  {
    id: 15,
    name: "Photoshop",
    badge: "images/img-svg-skills/Adobe_Photoshop_CS6_icon.svg",
  },
  {
    id: 14,
    name: "figma",
    badge: "images/img-svg-skills/Figma-logo.svg",
  },
  {
    id: 17,
    name: "Jira",
    badge: "images/img-svg-skills/jira.svg",
  },
  {
    id: 18,
    name: "Confluence",
    badge: "images/img-svg-skills/confluence.svg",
  },
  {
    id: 19,
    name: "GIT",
    badge: "images/img-svg-skills/git.svg",
  },
  {
    id: 23,
    name: "Docker",
    badge: "images/img-svg-skills/docker.svg",
  },

  {
    id: 24,
    name: "Kubernetes",
    badge: "images/img-svg-skills/Kubernetes.svg",
  },
  // {
  //   id: 11,
  //   name: "PHP",
  //   badge: "images/img-svg-skills/php.svg",
  // },
];
